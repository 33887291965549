
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Privacy Policy in compliance with art. 13 of d.lg. n. 196/2003 (Codice in materia di protezione dei dati personali) which describes how this website manages the personal data concerning the users visiting it.`}</p>
    <p>{`This document only applies to `}<em parentName="p"><a parentName="em" {...{
          "href": "http://www.emanueleperuffo.com",
          "target": "_blank",
          "rel": "noopener"
        }}>{`www.emanueleperuffo.com`}</a></em>{` and does not concern any other website visited following links.`}</p>
    <h2>{`Data Controller`}</h2>
    <p>{`Emanuele Peruffo`}<br parentName="p"></br>{`
`}{`Via Padova 31, Milano, Italia`}</p>
    {
      /* CF: PRFMNL87D07F464Z
      P.IVA: 03904670241 */
    }
    <h2>{`Processed Data`}</h2>
    <h3>{`Navigation Data`}</h3>
    <p>{`The information systems and software procedures relied upon to operate this web site acquire personal data as part of their standard functioning; the transmission of such data is an inherent feature of Internet communication protocols.`}</p>
    <p>{`Such information is not collected in order to relate it to identified data subjects, however it might allow user identification per se after being processed and matched with data held by third parties.`}</p>
    <p>{`This data category includes IP addresses and/or the domain names of the computers used by any user connecting with this web site, the URI (Uniform Resource Identifier) addresses of the requested resources, the time of such requests, the method used for submitting a given request to the server, returned file size, a numerical code relating to server response status (successfully performed, error, etc.), and other parameters related to the user’s operating system and computer environment.`}</p>
    <p>{`These data are only used to extract anonymous statistical information on website use as well as to check its functioning. The data might be used to establish liability in case computer crimes are committed against the website.`}</p>
    <h3>{`Data Provided Voluntarily by Users`}</h3>
    <p>{`Submitting data using the form on the `}<em parentName="p">{`Contact`}</em>{` page, which is done on the basis of a freely chose, explicit, and voluntary option, an email is sent containing the specified information. This email will be stored by the mail server until deletion by the recipient.`}</p>
    <p>{`Sending e-mail messages to the addresses mentioned on this website, which is done on the basis of a freely chosen, explicit, and voluntary option, entails acquisition of the sender’s address, which is necessary in order to reply to any request, as well as any additional personal data contained in the messages. The emails will be stored by the mail server until deletion by the recipient.`}</p>
    <h2>{`Processing Arrangements`}</h2>
    <p>{`Data is processed by automated means, it is stored electronically either by internal servers or by virtual servers in the cloud and it is not disclosed to third parties except by state laws.`}</p>
    <p>{`Specific security measures are implemented to prevent the data from being lost, used unlawfully and/or inappropriately, and accessed without authorization.`}</p>
    <p>{`Data may be accessed by the data controller, people responsible and managers in charge of the data management if nominated and any technicians or consultants managing the website and the information system of the company.`}</p>
    <p>{`The data is not disclosed to third parties.`}</p>
    <h2>{`Data Subjects’ Rights`}</h2>
    <ol>
      <li parentName="ol">{`The data subject has the right to obtain the confirmation of the existence of personal data of his concern, even if not yet registered, and its communication in an intelligible form.`}</li>
      <li parentName="ol">{`The data subject has the right to obtain the indication of:`}
        <ol parentName="li">
          <li parentName="ol">{`the data source;`}</li>
          <li parentName="ol">{`the purposes and the modality of the management of personal data;`}</li>
          <li parentName="ol">{`the logic applied when the management of personal data is performed with the aid of electronic devices;`}</li>
          <li parentName="ol">{`the identities of the data controller, of the people responsible for the management of personal data and of the agent elected in compliance with article 5, paragraph 2;`}</li>
          <li parentName="ol">{`the subjects or categories of subjects to whom the data can be communicated or who can become aware of the data as the agent elected for the state, as a person responsible for the management of personal data or as a manager.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`The data subject has the right to obtain:`}
        <ol parentName="li">
          <li parentName="ol">{`the updating, the rectification or, if the person is interested, the integration of the data;`}</li>
          <li parentName="ol">{`the cancellation, the transformation in an anonymous form or the locking of data managed unlawfully, including those of which the retention is not required to the purposes of the data collection or those subsequently processed;`}</li>
          <li parentName="ol">{`the declaration that the operations mentioned in the points a) and b) have been brought to the attention, also as regards to their content, of those to whom the data have been communicated or disclosed, except when this fulfillment proves impossible or involves the use of means clearly disproportionate to the right guaranteed.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`The data subject has the right to object, totally or in part:`}
        <ol parentName="li">
          <li parentName="ol">{`to the management of data of his concern for legitimate reasons, even if the data is relevant to the purpose of the data collection;`}</li>
          <li parentName="ol">{`to the management of data of his concern with the purpose of sending advertising or direct sales or conducting market researches or commercial communication.`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`Cookies`}</h2>
    <p>{`Cookies are small text fies saved by your browser`}</p>
    <p>{`This website uses cookies and similar technologies, also from third parties, to enhance your experience and for analytics. The choice to not accept cookies may limit the website functionalities.`}</p>
    <h3>{`Installed Cookies`}</h3>
    <ul>
      <li parentName="ul">{`locale: Used to remember the language settings.`}</li>
    </ul>
    <h3>{`Cookie from Google Analytics`}</h3>
    <p>{`Google Analytics is a service that creates statistics on the traffic on this website.`}</p>
    <p>{`This website uses Google Analytics thanks to his library `}<em parentName="p">{`ga.js`}</em>{`.`}</p>
    <p>{`To make this possible, Google Analytics could save one of these cookies:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`utma: Used to distinguish users and sessions. The cookie is created when the javascript library executes and no existing `}</strong>{`utma cookies exists. The cookie is updated every time data is sent to Google Analytics.`}</li>
      <li parentName="ul">{`__utmt: Used to throttle request rate.`}</li>
      <li parentName="ul"><strong parentName="li">{`utmb: Used to determine new sessions/visits. The cookie is created when the javascript library executes and no existing `}</strong>{`utmb cookies exists. The cookie is updated every time data is sent to Google Analytics.`}</li>
      <li parentName="ul"><strong parentName="li">{`utmc: Not used in ga.js. Set for interoperability with urchin.js. Historically, this cookie operated in conjunction with the `}</strong>{`utmb cookie to determine whether the user was in a new session/visit.`}</li>
      <li parentName="ul">{`__utmz: Stores the traffic source or campaign that explains how the user reached your site. The cookie is created when the javascript library executes and is updated every time data is sent to Google Analytics.`}</li>
      <li parentName="ul">{`__utmv: Used to store visitor-level custom variable data. This cookie is created when a developer uses the _setCustomVar method with a visitor level custom variable. This cookie was also used for the deprecated _setVar method. The cookie is updated every time data is sent to Google Analytics.`}</li>
      <li parentName="ul">{`__utmx: Used to determine a user’s inclusion in an experiment.`}</li>
      <li parentName="ul">{`__utmxx: Used to determine the expiry of experiments a user has been included in.`}</li>
      <li parentName="ul">{`_gaexp: Used to determine a user’s inclusion in an experiment and the expiry of experiments a user has been included in.`}</li>
    </ul>
    <p>{`Please read the `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy?hl=en",
        "target": "_blank",
        "rel": "noopener"
      }}>{`Privacy Policy of Google Analytics`}</a>{` and their `}<a parentName="p" {...{
        "href": "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage",
        "target": "_blank",
        "rel": "noopener"
      }}>{`usage of Cookies`}</a>{` for more information.`}</p>
    <h3>{`Cookie Management`}</h3>
    <h4>{`Browser Cookies`}</h4>
    <p>{`You can allow or decline the registration of cookies changing the settings of your browser:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Internet Explorer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.google.com/chrome/answer/95647?hl=en",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Google Chrome`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Mozilla Firefox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.apple.com/kb/PH17191?viewlocale=en_US&locale=en_US",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Apple Safari`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://help.opera.com/Windows/10.00/en/cookies.html",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Opera`}</a></li>
    </ul>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    